@import "../variables";

.why-choose {
  position: relative;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto 136px;
  padding: 58px 128px 12px;
  
  @media #{$media-desktop-lg} {
    padding: 58px 16px 12px;
  }
  
  @media #{$media-tablet-md} {
    padding: 0 16px;
    margin-bottom: 38px;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-color: $accent-background;
    opacity: .1;
    
    @media #{$media-tablet-md} {
      content: none;
    }
  }
  
  &__num {
    transform: translate(-2%, -47%);
  }
  
  &__header {
    margin-bottom: 55px;
    
    @media #{$media-tablet-md} {
      margin-bottom: 39px;
    }
  }
  
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  &__item {
    display: flex;
    align-items: center;
    flex-basis: 28.9%;
    margin-bottom: 52px;
    
    @media #{$media-tablet-md} {
      flex-basis: 48%;
      margin-bottom: 22px;
    }
    
    @media #{$media-phone-lg} {
      flex-basis: 100%;
    }
  }
  
  &__item-icon {
    display: block;
    margin-right: 20px;
  }
  
  &__item-text {
    font-size: 18px;
    line-height: 23px;
  }
}