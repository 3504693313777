@import "../variables";

.free-consultation {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto 285px;
  padding: 0 100px;
  display: flex;
  justify-content: flex-end;
  
  @media #{$media-desktop-md} {
    padding: 0 32px;
  }
  
  @media #{$media-tablet-md} {
    padding: 0 16px;
    margin-bottom: 50px;
  }
  
  @media #{$media-tablet-sm} {
    justify-content: center;
  }
  
  &__inner {
    @media #{$media-tablet-sm} {
      width: 100%;
    }
  }
  
  &__header {
    margin-bottom: 20px;
    
    @media #{$media-tablet-md} {
      margin-bottom: 15px;
    }
  }
  
  &__description {
    font-size: 18px;
    line-height: 22px;
    color: $accent-color;
    margin-bottom: 44px;
    
    @media #{$media-tablet-md} {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 17px;
    }
  }
  
  &__form {
    @media #{$media-tablet-sm} {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }
  
  &__input {
    margin-right: 24px;
    
    @media #{$media-tablet-sm} {
      margin: 0 0 5px 0;
      display: block;
      max-width: none;
      width: 100%;
    }
    
    &_last {
      @media #{$media-tablet-sm} {
        margin-bottom: 20px;
      }
    }
  }
}