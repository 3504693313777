@import "../variables";

.button {
  display: inline-block;
  border: none;
  background-color: $accent-color;
  padding: 11px 24px 10px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: .4px;
  line-height: 19px;
  transition: background-color .3s;
  color: #ffffff;
  outline: none;
  
  &:hover, &:focus {
    color: #ffffff;
    background-color: darken($accent-color, 15%);
  }
  
  &_shadow {
    box-shadow: 0 0 20px $accent-color;
    
    &:hover {
      box-shadow: 0 0 20px darken($accent-color, 15%);
    }
  }
  
  &_regular-white {
    background: none;
    border: 1px solid #ffffff;
    transition: color .3s, background-color .3s;
    
    &:hover {
      background: #ffffff;
      color: $accent-color;
    }
  }
}