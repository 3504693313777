@import "../variables";

.steps {
  display: flex;
  
  @media #{$media-desktop-sm} {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  &__item {
    flex-shrink: 0;
    padding: 0 20px;
    
    @media #{$media-desktop-sm} {
      flex-basis: 25%;
      padding: 0;
      margin-bottom: 25px;
    }
    
    @media #{$media-phone-lg} {
      flex-basis: 49%;
    }
  }
  
  &__num {
    position: relative;
    display: inline-block;
    font-family: "Monoton", sans-serif;
    color: $accent-color;
    font-size: 40px;
    margin: 0 80px 20px 0;
    
    @media #{$media-desktop-sm} {
      font-size: 72px;
    }
    
    @media #{$media-tablet-md} {
      font-size: 50px;
    }
    
    @media #{$media-tablet-sm} {
      font-size: 38px;
    }
  }
  
  
  &__num-hr {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 130%;
      width: 80px;
      height: 2px;
      background-color: #ffffff;
      
      @media #{$media-desktop-sm} {
        right: 130px;
      }
      
      @media screen and (max-width: 1000px) {
        right: 120px;
        width: 70px;
      }
      
      @media screen and (max-width: 920px) {
        width: 60px;
      }
      
      @media #{$media-tablet-md} {
        right: 100px;
      }
      
      @media screen and (max-width: 820px) {
        width: 50px;
      }
      
      @media screen and (max-width: 770px) {
        width: 40px;
      }
      
      @media #{$media-tablet-sm} {
        right: 80px;
      }
      
      @media #{$media-phone-lg} {
        content: none;
      }
    }
    
    &_first {
      &::before {
        display: none;
      }
    }
  }
  
  &__text {
    font-size: 16px;
    line-height: 20px;
  }
}