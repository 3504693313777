@import "../variables";

.about {
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 230px;
    background-color: $accent-background;
    z-index: -1;
    opacity: .1;
    
    @media #{$media-desktop-lg} {
      height: 17%;
    }
    
    @media screen and (max-width: 1300px) {
      height: 13%;
    }
   
    @media screen and (max-width: 1290px) {
      height: 10%;
    }
    
    @media #{$media-tablet-md} {
      display: none;
    }
  }
  
  &__inner {
    padding: 0 61px;
    display: flex;
    
    @media #{$media-desktop-sm} {
      padding: 0 16px;
    }
    
    @media #{$media-tablet-md} {
      flex-direction: column;
    }
  }
  
  &__main {
    flex-basis: 28%;
  }
  
  &__header {
    margin-bottom: 20px;
  }
  
  &__information {
    white-space: pre-line;
    font-size: 16px;
    line-height: 20px;
    
    @media #{$media-tablet-md} {
      margin-bottom: 25px;
    }
  }
  
  &__cards-wrapper {
    display: flex;
    flex-basis: 72%;
    
    @media #{$media-desktop-sm} {
      width: 65%;
    }
    
    @media #{$media-tablet-md} {
      width: 100%;
      justify-content: space-between;
    }
    
    @media #{$media-phone-lg} {
      flex-direction: column;
    }
  }
  
  &__cards-col {
    width: 49%;
    padding-left: 40px;
  
    @media #{$media-desktop-sm} {
      padding-left: 16px;
    }
    
    @media #{$media-tablet-md} {
      padding-left: 0;
    }
    
    @media #{$media-phone-lg} {
      width: 100%;
    }
  }
  
  &__card {
    margin-bottom: 60px;
    width: 100%;
    
    @media #{$media-tablet-md} {
      max-width: none;
    }
  }
  
  &__button-wrapper {
    display: inline-block;
    box-sizing: content-box;
    
    @media #{$media-tablet-md} {
      display: none;
    }
  }
  
  &__hr {
    margin: 25px 0;
  }
}