@import "../variables";

.text-with-icon {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  
  &__image {
    background-color: #494949;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
    flex-shrink: 0;
  }
  &__block{
    display: grid;
  }
  &__text {
    color: $inert;
    font-size: 14px;
    
    &_link {
      transition: color .3s;
      padding: 3px;
      
      @media #{$media-phone-lg} {
        color: #ffffff;
      }
      
      &:hover {
        color: #ffffff;
      }
    }
  }
}