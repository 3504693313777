@import "../variables";

.how-working {
  position: relative;
  display: flex;
  align-items: center;
  background: url("../img/how-working-background.png");
  background-size: cover;
  
  @media #{$media-tablet-md} {
    margin-bottom: 25px;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    z-index: 0;
    opacity: .7;
  }
  
  &__main-wrapper {
    position: relative;
    flex-basis: 38%;
    display: flex;
    justify-content: flex-end;
    margin: -30px 0;
    padding: 61px 60px;
    z-index: 2;
    
    @media #{$media-desktop-sm} {
      margin: 0;
      flex-basis: 100%;
      justify-content: center;
    }
    
    @media #{$media-phone-md} {
      padding: 19px 16px 28px;
    }
  }
  
  &__accent-background {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $accent-color;
    z-index: -1;
    transition: right .1s;
    
    @media #{$media-desktop-sm} {
      display: none;
    }
  }
  
  &__main {
    color: #ffffff;
    max-width: 311px;
    
    @media #{$media-desktop-sm} {
      max-width: none;
    }
  }
  
  &__header {
    font-weight: 500;
    font-size: 56px;
    line-height: 70px;
    margin-bottom: 30px;
    
    @media #{$media-phone-lg} {
      font-size: 36px;
      line-height: 45px;
    }
  }
  
  &__header-accent {
    color: #000;
    transition: color .1s;
    
    @media #{$media-desktop-sm} {
      color: $accent-color!important;
    }
  }
  
  &__text {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 30px;
    
    @media #{$media-tablet-sm} {
      font-size: 16px;
    }
  }
  
  &__steps {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    padding-left: 44px;
    
    @media #{$media-desktop-md} {
      padding-left: 15px;
    }
    
    @media #{$media-desktop-sm} {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      padding-left: 0;
    }
  }
}