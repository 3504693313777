//Colors
$accent-color: #ff2427;
$error-color
: #ff2427;
$inert: #c4c4c4;
$section-num: #C8C8EC;
$vk: #4881B5;
$facebook: #4267A7;
$accent-background: #C8C8EC;
$mobile-sidebar-background: #2F2F2F;

//Media breakpoints
$desktop-lg: 1330px;
$desktop-md: 1280px;
$desktop-sm: 1100px;
$tablet-md: 900px;
$tablet-sm: 750px;
$phone-lg: 700px;
$phone-md: 500px;

//Media queries
$media-desktop-lg: "screen and (max-width: " + $desktop-lg + ")";
$media-desktop-md: "screen and (max-width: " + $desktop-md + ")";
$media-desktop-sm: "screen and (max-width: " + $desktop-sm + ")";
$media-tablet-md: "screen and (max-width: " + $tablet-md + ")";
$media-tablet-sm: "screen and (max-width: " + $tablet-sm + ")";
$media-phone-lg: "screen and (max-width: " + $phone-lg + ")";
$media-phone-md: "screen and (max-width: " + $phone-md + ")";