@import "scss/variables";

.header {
  position: relative;
  background: url("../img/header-background.png");
  background-size: cover;
  background-position: center;
  min-height: 800px;
  overflow: hidden;
  color: #ffffff;
  margin-bottom: 145px;
  
  @media #{$media-tablet-md} {
    margin-bottom: 34px;
  }
  
  @media #{$media-phone-md} {
    min-height: 560px;
    height: 110vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: rgba(1, 21, 72, 0.41);
    
    @media #{$media-desktop-sm} {
      background: rgba(0, 0, 0, .7);
    }
  }
  
  &__red-lines {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 1300px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    
    &::before, &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 150%;
      height: 220px;
    }
    
    &::before {
      background: linear-gradient(-90deg, #E8212E 0%, #7A2A2D 70.59%);
      transform: translateX(50%) rotate(60deg) translateY(100%);
    }
    
    &::after {
      background: linear-gradient(90deg, #782A2A -0.96%, $accent-color 93.77%);
      transform: translateX(50%) rotate(-60deg) translateY(-55%);
    }
    
    @media #{$media-desktop-lg} {
      &::before, &::after {
        right: 15px;
      }
    }
    
    @media #{$media-desktop-md} {
      &::after {
        width: 170%;
      }
    }
    
    @media #{$media-desktop-sm} {
      position: relative;
      left: 0;
      transform: none;
      height: auto;
      
      &::before, &::after {
        display: none;
      }
    }
    
    @media #{$media-phone-md} {
      max-height: 25%;
    }
  }
  
  &__logo {
    position: absolute;
    right: 100px;
    bottom: 0;
    padding-bottom: 15px;
    
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 250%;
      height: 160%;
      transform: translateX(-50%);
      z-index: -1;
      background-color: #000;
      opacity: .7;
    }
    
    @media #{$media-desktop-lg} {
      right: 115px;
    }
    
    @media #{$media-desktop-sm} {
      position: relative;
      right: 0;
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      
      &::before {
        display: none;
      }
    }
    
    @media #{$media-phone-md} {
      margin-bottom: 20px;
      height: 100%;
      
      img {
        object-fit: contain;
      }
    }
  }
  
  &__top-line-wrapper {
    margin-bottom: 125px;
  
    @media #{$media-desktop-sm} {
      margin-bottom: 50px;
    }
  
    @media #{$media-tablet-md} {
      margin-bottom: 0;
    }
  }
  
  &__top-line {
    padding-bottom: 25px;
    width: 100%;
    z-index: 100;
    transition: background-color .3s;
    
    &[data-stuck="true"] {
      background-color: rgba(0, 0, 0, .5);
    }
    
    @media #{$media-tablet-md} {
      padding-bottom: 0;
    }
    
    @media #{$media-phone-md} {
      flex-basis: 10%;
    }
  }
  
  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 1;
    
    @media #{$media-desktop-sm} {
      align-items: center;
      text-align: center;
    }
  
    @media #{$media-phone-md} {
      margin: 0;
      flex-basis: 60%;
      justify-content: space-between;
    }
  }
  
  &__title {
    font-size: 48px;
    letter-spacing: .4px;
    font-weight: 500;
    line-height: 60px;
    text-transform: uppercase;
    max-width: 750px;
    margin-bottom: 22px;
    
    @media #{$media-desktop-md} {
      font-size: 42px;
    }
    
    @media #{$media-phone-lg} {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 16px;
    }
    
    @media screen and (max-height: 560px) and (max-width: 400px) {
      font-size: 23px;
      line-height: 27px;
    }
  }
  
  &__title-br {
    display: none;
    
    @media #{$media-phone-lg} {
      display: block;
    }
  }
  
  &__hr {
    margin-bottom: 37px;
    
    @media #{$media-phone-lg} {
      margin-bottom: 16px;
    }
  }
  
  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: .4px;
    max-width: 430px;
    
    @media #{$media-phone-lg} {
      font-size: 14px;
      line-height: 18px;
    }
    
    &_first {
      margin-bottom: 139px;
      
      @media #{$media-desktop-sm} {
        margin-bottom: 40px;
      }
      
      @media #{$media-phone-md} {
        margin-bottom: 16px;
      }
    }
    
    &_form {
      max-width: none;
      margin-bottom: 32px;
  
      @media #{$media-phone-md} {
        margin-bottom: 16px;
      }
  
      @media screen and (max-height: 600px) and (max-width: 400px) {
        margin-bottom: 12px;
      }
    }
  }
  
  &__form {
    position: relative;
    margin-bottom: 95px;
    
    @media #{$media-phone-lg} {
      flex-direction: column;
      align-items: stretch;
      width: 100%;
    }
    
    @media #{$media-phone-md} {
      margin-bottom: 25px;
    }
  }
  
  &__form-error {
    position: absolute;
    top: 138%;
    
    @media #{$media-phone-lg} {
      top: 108%;
    }
  }
  
  &__phone-input {
    margin-right: 24px;
    
    @media #{$media-phone-lg} {
      display: block;
      margin-bottom: 20px;
      width: 100%;
      max-width: none;
      margin-right: 0;
    }
  }
  
  &__social-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    
    @media #{$media-phone-lg} {
      display: none;
    }
  }
  
  &__social-item {
    fill: #ffffff;
    transition: fill .3s;
    
    &_vk {
      width: 32px;
      margin-right: 13px;
      
      &:hover {
        fill: $vk;
      }
    }
    
    &_instagram {
      width: 24px;
      margin-right: 24px;
      
      &:hover {
        fill: url(#instagramGradient);
      }
    }
    
    &_text {
      color: $accent-color;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: .4px;
      transition: color .3s;
      
      &:hover {
        color: darken($accent-color, 15%);
      }
    }
  }
}