.animation-opacity {
  transition: opacity 1s;
  opacity: 1;
  
  &.el-hide {
    transition: none;
    opacity: 0;
  }
}

.animation-opacity-bottom {
  transition: all 1s, background-color .3s;
  opacity: 1;
  transform: translateY(0);
  
  &.el-hide {
    transition: none;
    opacity: 0;
    transform: translateY(50px);
  }
}

.animation-opacity-left {
  transition: all 1s;
  opacity: 1;
  transform: translateX(0);
  
  &.el-hide {
    transition: none;
    opacity: 0;
    transform: translateX(-50px);
  }
}

.animation-top {
  transition: transform 1s;
  transform: translateY(0);
  
  &.el-hide {
    transition: none;
    transform: translateY(-50px);
  }
}