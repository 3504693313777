@import "../variables";

.services {
  display: flex;
  justify-content: center;
  margin-bottom: 130px;
  padding: 0 16px;
  
  @media #{$media-tablet-md} {
    flex-direction: column;
    margin-bottom: 0;
  }
  
  &__items-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-basis: 60%;
    flex-shrink: 0;
    padding: 110px 50px 0 0;
    
    @media #{$media-desktop-sm} {
      flex-basis: 50%;
    }
    
    @media #{$media-tablet-md} {
      flex-basis: auto;
      margin-bottom: 21px;
      padding: 0;
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1000%;
      height: 100%;
      background-color: $accent-background;
      opacity: .1;
      z-index: -1;
      
      @media #{$media-tablet-md} {
        content: none;
      }
    }
  }
  
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 680px;
    
    @media #{$media-tablet-md} {
      max-width: none;
    }
  }
  
  &__item {
    flex-basis: 31.5%;
    margin-bottom: 24px;
    
    @media #{$media-desktop-sm} {
      flex-basis: 48%;
      max-width: none;
    }
    
    @media #{$media-phone-md} {
      flex-basis: 100%;
    }
  }
  
  &__info-wrapper {
    flex-basis: 40%;
    flex-shrink: 0;
    padding: 204px 0 0 66px;
    
    @media #{$media-desktop-sm} {
      flex-basis: 50%;
    }
    
    @media #{$media-tablet-md} {
      padding: 0;
    }
  }
  
  &__info-inner {
    max-width: 394px;
    
    @media #{$media-tablet-md} {
      max-width: none;
    }
  }
  
  &__num {
    transform: translate(-10%, -55%);
  }
  
  &__header {
    position: relative;
    margin-bottom: 20px;
    
    &::before {
      content: '';
      position: absolute;
      right: 106%;
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      width: 78px;
      background-color: $accent-color;
    }
    
    &_desktop {
      @media #{$media-tablet-md} {
        display: none;
      }
    }
    
    &_mobile {
      display: none;
      padding-left: 24px;
      font-size: 36px;
      margin-bottom: 10px;
      
      @media #{$media-tablet-md} {
        display: block;
      }
      
      &::before {
        right: 99%;
        
        @media #{$media-phone-lg} {
          right: 97%;
        }
      }
    }
  }
  
  &__description {
    font-size: 18px;
    line-height: 23px;
    color: $accent-color;
    margin-bottom: 35px;
    
    &_desktop {
      @media #{$media-tablet-md} {
        display: none;
      }
    }
    
    &_mobile {
      display: none;
      padding-left: 24px;
      font-size: 16px;
      line-height: 20px;
      
      @media #{$media-tablet-md} {
        display: block;
      }
    }
  }
  
  &__gallery {
    position: relative;
    margin-bottom: 40px;
    
    @media #{$media-tablet-md} {
      margin-bottom: 25px;
    }
  }
  
  &__gallery-description {
    position: absolute;
    left: -20px;
    bottom: 50px;
    transform: translateX(-50%) rotate(-90deg) translateX(50%);
    z-index: -1;
    font-size: 18px;
    
    @media #{$media-tablet-md} {
      position: relative;
      transform: none;
      left: 0;
      bottom: 0;
      margin-bottom: 24px;
    }
  }
  
  &__gallery-description-accent {
    color: $accent-color;
  }
}