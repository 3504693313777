@import "../variables";

.logo-active-switch {
  position: relative;
  max-height: 100%;
  max-width: 100%;
  
  &__img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: opacity .3s;
    
    @media #{$media-tablet-sm} {
      display: none;
    }
    
    &_active {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100%;
      height: 100%;
      opacity: 0;
      
      @media #{$media-tablet-sm} {
        display: block;
        position: relative;
        height: auto;
        opacity: 1;
      }
    }
  }
  
  &:hover &__img {
    opacity: 0;
    
    &_active {
      opacity: 1;
    }
  }
}