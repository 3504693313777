@import "../variables";

.our-clients {
  margin-bottom: 100px;
  
  @media #{$media-tablet-md} {
    margin-bottom: 40px;
  }
  
  &__header-wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto 45px;
    padding: 0 100px;
    display: flex;
    justify-content: flex-end;
    
    @media #{$media-tablet-md} {
      margin-bottom: 16px;
    }
    
    @media #{$media-tablet-sm} {
      padding: 0 16px;
      justify-content: flex-start;
    }
  }
  
  &__num {
    transform: translate(-7%, -54%);
  }
  
  &__items {
    @media #{$media-tablet-sm} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
  
  &__client-item {
    padding: 0 62px;
    max-height: 215px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media #{$media-desktop-sm} {
      padding: 0 30px;
    }
    
    @media #{$media-tablet-sm} {
      padding: 0 16px;
      flex-basis: 50%;
      margin-bottom: 30px;
    }
  }
}