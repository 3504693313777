@import "../variables";

.red-hr {
  position: relative;
  width: 100%;
  height: 2px;
  border: none;
  margin: 0;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $accent-color;
  }
  
  &_vertical {
    height: 78px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      background-color: $accent-color;
    }
  }
}