@import "../variables";

.square-gallery {
  
  &__gallery-wrapper {
    position: relative;
    padding-bottom: 100%;
    margin-bottom: 12px;
  }
  
  &__images-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  &__navigation-item {
    background-color: $accent-color;
    border: none;
    padding: 12px 15.5px 8px;
    margin-right: 2px;
    transition: background-color .3s;
    outline: none;
    
    &:hover {
      background-color: darken($accent-color, 15%);
    }
  }
  
  &__navigation-arrow {
    &_right {
      transform: rotate(180deg);
    }
  }
  
  &__gallery-link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    display: block;
    
    &::before {

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $accent-color;
      opacity: 0;
      transition: opacity .3s;
    }
    
    &:hover::before {
      opacity: .3;
    }
    
    .is-active & {
      position: relative;
      z-index: 1;
    }
    
    &_first {
      position: relative;
      z-index: 1;
    }
  }
  
  &__img {
    display: block;
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}