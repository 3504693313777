@import "../variables";

.form-error {
  display: none;
  height: 0;
  width: 100%;
  color: $error-color;
  font-size: 14px;
  margin-bottom: 10px;
  transition: opacity .3s, height .3s;
  
  &.error-active {
    opacity: 1;
    height: 16px;
  }
}