@import "../variables";

.service-card {
  position: relative;
  display: block;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(4, 4, 60, 0.25);
  padding: 16px 24px;
  max-width: 214px;
  transition: background-color .3s;
  
  &:hover {
    background-color: $accent-color;
  }
  
  &__icon {
    display: block;
    stroke: $accent-color;
    margin-bottom: 34px;
    transition: stroke .3s;
    min-height: 50px;
  }
  
  &:hover &__icon {
    stroke: #ffffff;
  }
  
  &__text {
    font-size: 16px;
    line-height: 20px;
    transition: color .3s;
  }
  
  &__text-delimiter {
    display: none;
    
    @media #{$media-desktop-md} {
      display: inline;
    }
    
    @media #{$media-tablet-md} {
      display: none;
    }
  }
  
  &:hover &__text {
    color: #ffffff;
  }
  
  &__num {
    position: absolute;
    top: 18px;
    right: 20px;
    font-family: "Monoton", sans-serif;
    color: #000;
    opacity: .1;
    font-size: 16px;
    transition: opacity .3s, color .3s;
  }
  
  &:hover &__num {
    color: #ffffff;
    opacity: 1;
  }
}
.square-gallery__gallery-wrapper{
  margin-bottom: 30px!important;
}