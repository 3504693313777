@import "../variables";

.about-card {
  max-width: 390px;
  position: relative;
  margin-bottom: 40px;
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: #000000;
    opacity: .4;
  }
  
  &__img {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  
    @media #{$media-phone-lg} {
      max-height: 161px;
    }
  }
  
  &__title {
    position: absolute;
    top: 30px;
    max-width: 80%;
    color: #ffffff;
    z-index: 3;
    font-size: 18px;
    font-weight: 500;
    padding-left: 18px;
    line-height: 23px;
    overflow: hidden;
    
    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      transform: translateY(-50%);
      height: 30px;
      width: 48px;
      z-index: -1;
      background-color: $accent-color;
    }
  }
  
  &__more-wrapper {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    transition: all .5s linear;
    z-index: 5;
    
    .open & {
      background-color: #fff;
      box-shadow: 0 0 20px rgba(255, 36, 39, 0.25);
      padding: 30px 0;
      z-index: 6;
      
      @media #{$media-tablet-md} {
        background: none;
        box-shadow: none;
        padding: 0;
        z-index: 5;
      }
    }
  }
  
  &__more-body {
    display: none;
    font-size: 16px;
    line-height: 20px;
    height: 0;
    opacity: 0;
    transition: all .5s linear;
    overflow: hidden;
    padding: 0 24px;
    
    .open & {
      opacity: 1;
      display: block;
      
      @media #{$media-tablet-md} {
        display: none!important;
      }
    }
  }
  
  &__more-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 16px 0 0;
    
    @media #{$media-phone-lg} {
      padding-right: 0;
    }
  }
  
  &__arrow-icon {
    margin-right: 10px;
    transform: rotate(180deg);
    transition: all .3s linear .3s;
    
    @media #{$media-tablet-md} {
      margin-bottom: 3px;
      transform: rotate(-90deg);
      height: 20px;
    }
    
    .open & {
      transform: rotate(0deg);
      
      @media #{$media-tablet-md} {
        transform: rotate(-90deg);
      }
    }
  }
  
  &__more-text {
    font-size: 16px;
    text-transform: uppercase;
    color: $accent-color;
  }
  
}