@import "../variables";

.footer {
  background-color: #000000;
  padding: 56px 10px 73px;
  
  @media #{$media-phone-lg} {
    padding: 30px 10px 9px;
  }
  
  &__inner {
    max-width: 1170px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
    @media #{$media-phone-lg} {
      flex-direction: column;
      align-items: stretch;
    }
  }
  
  &__logo {
    max-width: 144px;
    object-fit: cover;
    padding: 3px 0;
    
    @media #{$media-desktop-sm} {
      display: none;
    }
  }
  
  &__col {
    max-width: 250px;
    
    @media #{$media-desktop-sm} {
      padding: 0 6px;
    }
    
    @media #{$media-phone-lg} {
      margin-bottom: 12px;
      max-width: none;
    }
    
    &_props {
      max-width: 383px;
      
      @media #{$media-phone-lg} {
        max-width: none;
      }
    }
  }
  
  &__col-header {
    color: $accent-color;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  
  &__col-item {
    display: block;
    margin-bottom: 13px;
    font-size: 12px;
    color: $inert;
    line-height: 15px;
    
    &_link {
      transition: color .3s;
      
      &:focus {
        color: $inert;
      }
  
      &:hover {
        color: #ffffff;
      }
    }
    
    &_props {
      white-space: pre-line;
      line-height: 19px;
      margin-bottom: 20px;
    }
  }
  
  &__text {
    margin-bottom: 11px;
    font-size: 12px;
    line-height: 15px;
    color: $inert;
    letter-spacing: normal;
  }
  
  &__socials {
    @media #{$media-phone-lg} {
      margin-bottom: 20px;
    }
  }
  
  &__policy-wrapper {
    
    @media #{$media-desktop-sm} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    
    &_desktop {
      @media #{$media-phone-lg} {
        display: none;
      }
    }
    
    &_mobile {
      display: none;
      text-align: center;
      align-items: center;
      
      @media #{$media-phone-lg} {
        display: flex;
      }
    }
  }
  
  &__policy {
    margin-bottom: 11px;
    font-size: 12px;
    line-height: 15px;
    color: $inert;
    margin-right: 23px;
    border-bottom: 1px dotted $inert;
    transition: color .3s, border-bottom-color .3s;
    letter-spacing: normal;
    
    @media #{$media-desktop-sm} {
      margin-right: 0;
    }
    
    &:hover {
      color: #ffffff;
      border-bottom-color: #000000;
    }
  }
  
  &__contact-item {
    &_last {
      margin-bottom: 25px;
    }
  }
}