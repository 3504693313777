@import "../variables";

.big-text-section {
  position: relative;
  padding: 20px 0 19px;
  overflow: hidden;
  
  @media #{$media-tablet-md} {
    display: none;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $accent-background;
    opacity: .1;
  }
  
  &__text {
    font-size: 144px;
    font-weight: 800;
    text-transform: uppercase;
    opacity: 0.05;
    text-align: right;
    margin-bottom: 20px;
    
    @media #{$media-desktop-sm} {
      font-size: 100px;
    }
  }
  
  &__text-item {
    transition: right .1s, left .1s;
    position: relative;
  }
  
  &__additional-text {
    display: flex;
    justify-content: flex-end;
    padding-right: 100px;
  }
  
  &__additional-text-item {
    position: relative;
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    text-align: right;
    transition: right .1s, left .1s;
    
    &_second {
      color: $accent-color;
      padding-right: 20px;
    }
  }
}