@import "../variables";

.hamburger {
  position: relative;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  width: 26px;
  height: 20px;
  
  &__inner {
    display: block;
    height: 4px;
    width: 100%;
    background-color: $accent-color;
    border-radius: 10px;
    transition: background-color .3s ease .1s;
    
    .active & {
      transition: background-color .3s;
      background-color: rgba(255, 36, 39, 0);
    }
    
    &::before, &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 4px;
      background-color: $accent-color;
      border-radius: 10px;
      transition: all .3s;
      
      .active & {
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        transition: all .3s ease .1s;
      }
    }
    
    &::after {
      top: auto;
      bottom: 0;
      
      .active & {
        bottom: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}