@import "../variables";

.input {
  background: none;
  border: none;
  font-size: 17px;
  color: #ffffff;
  padding: 9px 0;
  border-bottom: 1px solid #ffffff;
  max-width: 202px;
  transition: border-bottom-color .3s;
  
  @media #{$media-tablet-md} {
    font-size: 16px;
  }
  
  &::placeholder {
    color: $inert;
    transition: color .3s;
  }
  
  &_grey {
    color: #000;
    border-color: $inert;
    padding: 9px 4px;
  }
  
  &_textarea {
    outline: none;
    min-height: 70px;
    width: 100%!important;
  }
  
  &.input-error {
    border-bottom-color: $error-color;
    color: $error-color;
    
    &:focus {
      color: inherit;
    }
    
    &::placeholder {
      color: $error-color;
    }
  }
}