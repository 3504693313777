@import "../variables";

.file-input {
  &__file-input {
    display: none!important;
  }
  
  &__files-wrapper {
    display: block;
    text-align: center;
    max-width: 70%;
    margin: auto;
  }
  
  &__file {
    display: block;
    color: $inert;
    font-size: 13px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
  
  &__button-wrapper {
    display: block;
    margin-bottom: 5px;
  }
  
  &__button {
    font-size: 13px;
    letter-spacing: 0.4px;
    padding: 7px 15px;
    cursor: pointer;
  }
}