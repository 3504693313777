html {
  font-family: "Geometria", sans-serif;
}

body {
  letter-spacing: .4px;
  color: #000;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 1322px;
  margin: auto;
  padding: 0 16px;
}

.select__opener span{
  margin-left: -3px;
}