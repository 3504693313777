@import "../variables";

.socials {
  display: flex;
  
  @media #{$media-phone-lg} {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  &_sidebar {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 17px;
    
    @media #{$media-phone-lg} {
      margin: 0 0 15px 0;
      flex-basis: 50%;
    }
    
    &_sidebar {
      margin: 0 0 15px 0;
      flex-basis: 100%;
    }
  }
  
  &__icon {
    fill: $inert;
    height: 20px;
    width: 20px;
    transition: fill .3s;
    
    @media #{$media-phone-lg} {
      margin-right: 12px;
      height: 25px;
      width: 25px;
    }
    
    &_sidebar {
      margin-right: 11px;
      height: 32px;
      width: 32px;
    }
  }
  
  &__item:hover &__icon {
    &_vk {
      fill: $vk;
    }
  
    &_inst {
      fill: url(#instagramGradient);
    }
  
    &_fb {
      fill: $facebook;
    }
  
    &_mail {
      fill: $accent-color;
    }
  }
  
  &__text {
    display: none;
    color: #ffffff;
    font-size: 12px;
    
    @media #{$media-phone-lg} {
      display: block;
    }
    
    &_sidebar {
      display: block;
      font-size: 16px;
      line-height: 20px;
    }
  }
}