@import "../variables";

.top-line {
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    &_desktop {
      @media #{$media-tablet-md} {
        display: none;
      }
    }
    
    &_mobile {
      display: none;
      padding: 16px 0;
      
      @media #{$media-tablet-md} {
        display: flex;
      }
    }
  }
  
  &__menu-wrapper {
    display: flex;
    align-items: center;
    z-index: 10;
    margin-top: 20px;
  }
  
  &__logo {
    z-index: 2;
    margin-right: 49px;
  }
  
  &__menu-item {
    position: relative;
    text-transform: uppercase;

    font-weight: 500;
    font-size: 14px;
    letter-spacing: .4px;
    margin-right: 49px;
    transition: color .3s;
  
    &:focus {
      color: #ffffff;
    }
    
    &:hover {
      color: $accent-color;
      text-decoration: none;
    }
    
    &::after {
      position: absolute;
      content: '';
      bottom: -3px;
      opacity: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: $accent-color;
      transition: width .3s, opacity .3s;
    }
    
    &:hover::after {
      width: 100%;
      opacity: 1;
    }
  }
  
  &__information {
    position: relative;
    z-index: 1;
    display: grid;
  }
  
  &__phone {
    position: relative;
    transition: color .3s;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: .4px;
    text-shadow: rgba(0, 0, 0, .5) 0 5px 4px;
    padding: 4px;
    
    &:hover {
      color: $accent-color;
    }
  
    @keyframes blink_point {
      from {
        background-color: $inert;
      }
      
      to {
        background-color: $accent-color;
      }
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 45%;
      left: -10px;
      transform: translateY(-50%);
      height: 6px;
      width: 6px;
      background-color: $accent-color;
      animation: 1s linear 0s infinite alternate blink_point;
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 1) 0 3px 5px -1px;
    }
    
    &_mobile {
      display: block;
      line-height: 20px;
      
      &::before {
        content: none;
      }
    }
  }
  
  &__work-days {
    position: absolute;
    top: 110%;
    left: 0;
    width: 110%;
    font-weight: 400;
    font-size: 10px;
    letter-spacing: .4px;
    text-shadow: rgba(0, 0, 0, .4) 0 5px 4px;
  }
  
  &__phones-wrapper {
    display: flex;
    align-items: center;
    z-index: 1;
  }
  
  &__phone-icon {
    margin-right: 10px;
  }
  
  &__mobile-hamburger {
    display: block;
    z-index: 1;
  }
}