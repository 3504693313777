@import "../variables";

.mobile-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 80%;
  max-width: 450px;
  z-index: 1000;
  background-color: $mobile-sidebar-background;
  padding: 52px 26px 21px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  transform: translateX(85%);
  transition: all .5s;
  
  @media screen and (min-width: $media-tablet-md) {
    display: none!important;
  }
  
  &.open {
    opacity: 1;
    transform: translateX(0);
    transition: opacity .5s, transform .5s ease .2s;
  }
  
  &__close-button {
    position: absolute;
    top: 32px;
    left: 16px;
  }
  
  &__body {
    padding-top: 32px;
  }
  
  &__menu-item {
    margin-bottom: 11px;
  }
  
  &__menu-link {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: #ffffff;
    transition: color .3s;
    
    &:hover {
      color: $accent-color;
    }
  }
  
  &__socials {
    margin-bottom: 11px;
  }
  
  &__gallery-link {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: $accent-color;
    transition: color .3s;
    
    &:hover {
      color: darken($accent-color, 15%);
    }
  }
}