@import "../variables";

.red-white-hr {
  position: relative;
  width: 82px;
  height: 3px;
  border: none;
  margin: 0;
 
  &::before, &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
  }
  
  &::before {
    background-color: $accent-color;
    left: 0;
  }
  
  &::after {
    background-color: #fff;
    right: 0;
  }
}