@import "../variables";

.section {
  &__num {
    font-family: "Monoton", sans-serif;
    font-size: 288px;
    color: $section-num;
    opacity: .1;
    position: absolute;
    transform: translate(-15%, -40%);
    z-index: -1;
    
    @media #{$media-tablet-md} {
      display: none;
    }
  }
  
  &__header {
    font-size: 56px;
    font-weight: 500;
    line-height: 70px;
  
    @media #{$media-tablet-md} {
      font-size: 36px;
      line-height: 45px;
    }
  }
}